// import { StrictMode } from "react";
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './fonts/Kohinoor_Book.otf';
import './fonts/Kohinoor_Bold.otf';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// https://github.com/GoogleChrome/web-vitals#send-the-results-to-google-analytics
reportWebVitals();