import React, {useState, useRef, useLayoutEffect} from 'react';

import './ModalImprint.sass';

const ModalImprint = (props) => {

    let modal = useRef(null); 
    let firstFocusable, lastFocusable, moveFocusEl, modalFocus = null, selectedTrigger = null;
    const [showModal, setShowModal] = useState((props.showModal) ? props.showModal : false);
    const focusableElString = '[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex]:not([tabindex="-1"]), [contenteditable], audio[controls], video[controls], summary';

    const openModal = (e) => {
        setShowModal(true);
        getFocusableElements();
        selectedTrigger = e.currentTarget;
        if(moveFocusEl){
            moveFocusEl.focus();
            // wait for the end of transitions before moving focus
            modal.current.addEventListener("transitionend", function cb(event) {
                moveFocusEl.focus();
                modal.current.removeEventListener("transitionend", cb);
            });
        }
    };

    const closeModal = () => {
        setShowModal(false);
        firstFocusable = null;
        lastFocusable = null;
        moveFocusEl = null
        // set focus back to element that had it before the modal was opened
        if(selectedTrigger) selectedTrigger.focus();
        
    };

    window.addEventListener('keydown', function(event){ //close modal window on esc
        if(event.key && event.key.toLowerCase() === 'escape') {
            closeModal();
        } else if(event.key && event.key.toLowerCase() === 'tab') {
            trapFocus(event);
        }
    });

    const getFocusableElements = () => {
        //get all focusable elements inside the modal
        var allFocusable = modal.current.querySelectorAll(focusableElString);
        getFirstVisible(allFocusable);
        getLastVisible(allFocusable);
        getFirstFocusable();
    }

    const getFirstVisible = (elements) => {
        //get first visible focusable element inside the modal
        for(var i = 0; i < elements.length; i++) {
            if( isVisible(elements[i]) ) {
                firstFocusable = elements[i];
                break;
            }
        }
    }

    const getLastVisible = (elements) => {
        // get last visible focusable element inside the modal
        for(var i = elements.length - 1; i >= 0; i--) {
            if( isVisible(elements[i]) ) {
                lastFocusable = elements[i];
                break;
            }
        }
    }

    const getFirstFocusable = () => {
        if(!modalFocus || !Element.prototype.matches){
            moveFocusEl = firstFocusable;
            return;
        }
    };

    const isVisible = (element) => {
        return element.offsetWidth || element.offsetHeight || element.getClientRects().length;
    }

    const trapFocus = (event) => {
        if( firstFocusable === document.activeElement && event.shiftKey) {
            event.preventDefault();
            lastFocusable.focus();
        }
        if( lastFocusable === document.activeElement && !event.shiftKey) {
            event.preventDefault();
            firstFocusable.focus();
        }

    };

    const handelKeyDownModal = (event) => {
        if(event.key && event.key.toLowerCase() === 'escape') {
            closeModal();
        } else if(event.key && event.key.toLowerCase() === 'tab') {
            trapFocus(event);
        }
    };

    useLayoutEffect(() => {
        window.addEventListener('keydown', handelKeyDownModal);
        return () => {
            window.removeEventListener('keydown', handelKeyDownModal);
        };
    });

    return (
        <>

            <button onClick={openModal} aria-controls="modal-impressum" className='imprint-trigger'>
                Impressum
            </button>

            <div className={`modal modal--animate-scale ${(showModal) ? 'modal--is-visible' : ''}`} ref={modal}>
                <div id={props.name} className={`modal__content ${props.classes}`} role="alertdialog" aria-labelledby={props.title} aria-describedby={props.title}>
                    <button onClick={closeModal} className='modal__close-btn modal__close-btn--inner'>
                        <svg className="icon" viewBox="0 0 16 16">
                            <title>Modal Fenster schließen</title>
                                <g strokeWidth="1.5" stroke="currentColor" fill="none" strokeMiterlimit="10">
                                    <line x1="13.5" y1="2.5" x2="2.5" y2="13.5"></line>
                                    <line x1="2.5" y1="2.5" x2="13.5" y2="13.5"></line>
                                </g>
                        </svg>
                    </button>
                    {props.children}
                </div>
            </div>

        </>
    );
};
export default ModalImprint;