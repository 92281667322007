import './Error404.sass';

const Error404 = (props) => {


    return (    
        <div className='error404'>
            <h1>Die Seite konnte nicht gefunden werden</h1>
        </div>
    );
    
};

export default Error404;