import './Footer.sass';
import ModalImprint from '../Overlays/ModalImprint';


const Footer = () => {
    
    return (
        <footer className='footer'>
            <ModalImprint
                name='modal-impressum'
                title='Impressum'
                description='Impressum'
                classes='modal__content--imprint'
                showModal={false}
                >
                <p>
                    <strong>Impressum</strong>
                    <br /><br />
                    <strong>Herausgeberin</strong>
                    <br /><br />
                    {window.innerWidth >= 768 ?
                        <>
                            Unabhängige Kommission zur Aufarbeitung<br /> sexuellen Kindesmissbrauchs<br />
                            Glinkastraße 24<br />
                            10117 Berlin
                        </>
                                    :
                        <>
                            Unabhängige Kommission zur Aufarbeitung sexuellen Kindesmissbrauchs<br />
                            Glinkastraße 24<br />
                            10117 Berlin
                        </>
                    }
                    <br /><br />
                    Stand:<br />
                    Januar 2024
                    <br /><br />
                    <strong>Weitere Informationen</strong>
                    <br /><br />
                    E-Mail: <span className="break">kontakt@aufarbeitungskommission.de</span><br />
                    Webseite: www.aufarbeitungskommission.de<br />
                    Portal: www.geschichten-die-zaehlen.de<br />
                    Twitter: @Aufarbeitung<br />
                    Instagram: aufarbeitungskommission
                </p>
            </ModalImprint>
        </footer>
    );
    
};

export default Footer;