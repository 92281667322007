import React, { Suspense, lazy } from 'react';
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import RootLayout from "./pages/Root";
import ErrorPage from "./pages/Error";
import Loader from "./components/Layout/Loader";

import './App.sass';
import './components/Topics/TopicPageContent.sass';

import gsap from "gsap";

import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";


gsap.registerPlugin(ScrollTrigger,ScrollToPlugin);

// Animation
gsap.registerEffect({
  'name': 'slideIn',
  effect: (targets, config) => {
      return gsap.from(targets, {
          // opacity: 0,
          x: config.x,
          y: config.y,
          scrollTrigger: {
              trigger: config.trigger,
              start: config.start,
              end: config.end,
              scrub: true,
              markers: config.markers
          }
      }); 
  }
});
// Animation
gsap.registerEffect({
  'name': 'fadeIn',
  effect: (targets, config) => {
      return gsap.from(targets, {
          opacity: 0,
          scale: config.scale,
          scrollTrigger: {
              trigger: config.trigger,
              start: config.start,
              end: config.end,
              scrub: true,
              markers: config.markers
          }
      }); 
  }
});

gsap.registerEffect({
  'name': 'transform',
  effect: (targets, config) => {
      return gsap.from(targets, {
          y: config.y,
          x: config.x,
          scale: config.scale,
          scrollTrigger: {
              trigger: config.trigger,
              start: config.start,
              end: config.end,
              scrub: true,
              markers: config.markers
          }
      }); 
  }
});
gsap.registerEffect({
  'name': 'transformTo',
  effect: (targets, config) => {
      return gsap.to(targets, {
          y: config.y,
          x: config.x,
          scale: config.scale,
          scrollTrigger: {
              trigger: config.trigger,
              start: config.start,
              end: config.end,
              scrub: true,
              markers: config.markers
          }
      }); 
  }
});

gsap.registerEffect({
  'name': 'rotate',
  effect: (targets, config) => {
      return gsap.from(targets, {
          y: config.y,
          x: config.x,
          rotate: config.rotate,
          scrollTrigger: {
              trigger: config.trigger,
              start: config.start,
              end: config.end,
              scrub: true,
              markers: config.markers
          }
      }); 
  }
});

// changeNavBg is a function that changes the background color of the Navigations
const changeNavBg = (color) => {
  const header = document.getElementById('main-navigation');
  const arrowLinks = document.getElementsByClassName('arrow-navigation__link');
  const arrowList = document.getElementsByClassName('arrow-navigation__list');
  const arrowDown = document.getElementsByClassName('arrow-down__btn');

  header.style.backgroundColor = color;

  // change arrow link color
  if(arrowLinks.length > 0){
    for (let i = 0; i < arrowLinks.length; i++) {
      arrowLinks[i].style.backgroundColor = color;
    }
  }
  // change arrow down color
  if(arrowDown.length > 0){
    for (let i = 0; i < arrowDown.length; i++) {
      arrowDown[i].style.backgroundColor = color;
    }
  }

  // change arrow list color
  if(arrowList.length > 0){
    arrowList[0].style.color = color;
  }
};

const detectScreenWidth = () => {
  if(window.innerWidth <= 820){
    return 'tablet';
  }else{
    return 'desktop';
  }
};

const scrollRefresh = () => {
  ScrollTrigger.refresh();
}


// App is a component that returns a <RouterProvider> element
function App() {

  const HomePage = lazy(() => import("./pages/Home"));
  const WorkPage  = lazy(() => import("./pages/Work"));
  const HowItWorks  = lazy(() => import("./pages/HowItWorks"));
  const TopicsPage  = lazy(() => import("./pages/Topics"));
  const TopicDetails  = lazy(() => import("./pages/TopicDetails"));
  const StoryPage  = lazy(() => import("./pages/Story"));

  // createBrowserRouter is a API that returns a <BrowserRouter> element
  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootLayout/>,
      errorElement: <Suspense fallback={<Loader classes={'pink'}></Loader>}><ErrorPage/></Suspense>,
      children: [
        {
          index: true,
          element: <Suspense fallback={<Loader classes={'pink'}></Loader>}><HomePage  changeNavBg={changeNavBg}  scrollRefresh={scrollRefresh}/></Suspense>,
        },
        {
          path: "/unsere-arbeit",
          element: <Suspense fallback={<Loader classes={'blue'}></Loader>}><WorkPage changeNavBg={changeNavBg}  detectScreenWidth={detectScreenWidth} scrollRefresh={scrollRefresh}/></Suspense>,
        },
        {
          path: "/gemeinsam-bewegt",
          element: <Suspense fallback={<Loader classes={'pink'}></Loader>}><HowItWorks changeNavBg={changeNavBg} scrollRefresh={scrollRefresh}/></Suspense>,
        },
        {
          path: "/ihre-themen",
          element: <Suspense fallback={<Loader classes={'purple'}></Loader>}><TopicsPage changeNavBg={changeNavBg} /></Suspense>,
        },
        {
          path: "ihre-themen/:topicId",
          element: <Suspense fallback={<Loader classes={'purple'}></Loader>}><TopicDetails changeNavBg={changeNavBg} scrollRefresh={scrollRefresh}/></Suspense>,
        },
        {
          path: "ihre-geschichte-zaehlt",
          element: <Suspense fallback={<Loader classes={'green'}></Loader>}><StoryPage changeNavBg={changeNavBg} scrollRefresh={scrollRefresh}/></Suspense>,
        }
      ]
    }
  ])



  return (
    <>
        <RouterProvider router={router}  />
    </>
  );
}

export default App;