import MainNavigation from '../components/Navigation/MainNavigation.js';
import Error404 from '../components/Layout/Error404.js';

const ErrorPage = () => {
  return (
    <>
      <MainNavigation />
      <main className='page-content'>
        <Error404 />
      </main>
    </>
  );
}

export default ErrorPage;
