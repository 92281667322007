import {NavLink, useLocation} from 'react-router-dom';
import React, { useLayoutEffect, useRef } from 'react';
import { gsap } from "gsap";
import {api} from '../../Api';

import './ArrowNavigation.sass';

function ArrowNavigation() {

    const arrowNav = useRef(null);
    const location = useLocation();
    const ariaLive = useRef(null);
    const navList = useRef(null);

    let prevLink =  '';
    let nextLink =  '';

    const onEnterBtn = ({ currentTarget }) => {
        gsap.to(currentTarget, { scale: 1.2, duration: 0.4 });
    };
    const onLeaveBtn = ({ currentTarget }) => {
        gsap.to(currentTarget, { scale: 1, duration: 0.4 });
    };

    // create prev and next link
    const createPrevUrl = (index) => {
        if(index === 0) {
            prevLink = <NavLink to={api.paths[api.paths.length - 1]} className={"arrow-navigation__link"}  ><svg className="icon" viewBox="0 0 100 100"><title>Vorherige Seite</title><path d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"></path></svg></NavLink>
        } else {
            prevLink = <NavLink to={api.paths[index-1]}  className={"arrow-navigation__link"}  ><svg className="icon" viewBox="0 0 100 100"><title>Vorherige Seite</title><path d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"></path></svg></NavLink>
        }
    }
    const createNextUrl = (index) => {
        if(index === api.paths.length - 1) {
            nextLink =  <NavLink to={'/'}  className={"arrow-navigation__link next"}><svg className="icon" viewBox="0 0 100 100"><title>Nächste Seite</title><path d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"></path></svg></NavLink>;
        } else {
            nextLink =  <NavLink to={api.paths[index+1]}  className={"arrow-navigation__link next"}><svg className="icon" viewBox="0 0 100 100"><title>Nächste Seite</title><path d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"></path></svg></NavLink>;
        }
    };

    
    if(api.paths.indexOf(location.pathname) > -1) {
        createPrevUrl(api.paths.indexOf(location.pathname));
        createNextUrl(api.paths.indexOf(location.pathname));
        // if(api.paths.indexOf(location.pathname) === 3) 
        // {
        //     createPrevUrl(api.paths.indexOf(location.pathname));
        //     createNextUrl(14);
        // } 
        // else if(api.paths.indexOf(location.pathname) === 4)
        // {
        //     createPrevUrl(4);
        //     createNextUrl(api.paths.indexOf(location.pathname));
        // }
        // else if(api.paths.indexOf(location.pathname) === 15)
        // {
        //     createPrevUrl(4);
        //     createNextUrl(api.paths.indexOf(location.pathname));
        // }
        // else 
        // {
        //     createPrevUrl(api.paths.indexOf(location.pathname));
        //     createNextUrl(api.paths.indexOf(location.pathname));
        // }

    } 

    useLayoutEffect(() => {
        ariaLive.current.innerHTML = 'Seite ' + (api.paths.indexOf(location.pathname) + 1) + ' von 16';
        navList.current.setAttribute('data-content', (api.paths.indexOf(location.pathname) + 1) + '/16');
    });

    return (
        <div className="arrow-navigation" ref={arrowNav}>
            <nav>
                <ul ref={navList} className="arrow-navigation__list" role="navigation" aria-label="Arrow Navigation">
                    <li onMouseEnter={onEnterBtn} onMouseLeave={onLeaveBtn}>
                        {prevLink}
                    </li>
                    <li onMouseEnter={onEnterBtn} onMouseLeave={onLeaveBtn}>
                        {nextLink}
                    </li>
                </ul>
                <div ref={ariaLive} className="sr-only" aria-live="polite" aria-atomic="true">
                    Seite 1 von 16
                </div>
            </nav>
        </div>
    )
    
}
export default ArrowNavigation;