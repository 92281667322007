export const api = {
    // "paths": ['/', '/unsere-arbeit', '/gemeinsam-bewegt', '/ihre-themen', '/ihre-geschichte-zaehlt'],
    "paths": [
        '/', 
        '/unsere-arbeit', 
        '/gemeinsam-bewegt', 
        '/ihre-themen', 
        '/ihre-themen/akten-und-archive', 
        '/ihre-themen/ddr', 
        '/ihre-themen/familie', 
        '/ihre-themen/kirche', 
        '/ihre-themen/menschen-mit-behinderungen', 
        '/ihre-themen/organisierte-und-rituelle-gewalt', 
        '/ihre-themen/paedosexuelle-netzwerke',
        '/ihre-themen/schule',
        '/ihre-themen/sport',
        '/ihre-themen/zeugen-jehovas',
        '/ihre-themen/weitere-themen',
        '/ihre-geschichte-zaehlt'],
    "topic": [
        {id: 'akten-und-archive', title: 'Akten und Archive'},
        {id: 'ddr', title: 'ddr'},
        {id: 'familie', title: 'Familie'},
        {id: 'kirche', title: 'Kirche'},
        {id: 'menschen-mit-behinderungen', title: 'Menschen mit Behinderungen'},
        {id: 'organisierte-und-rituelle-gewalt', title: 'Organisierte und rituelle Gewalt'},
        {id: 'paedosexuelle-netzwerke', title: 'Pädosexuelle Netzwerke'},
        {id: 'schule', title: 'Schule'},
        {id: 'sport', title: 'Sport'},
        {id: 'zeugen-jehovas', title: 'Zeugen Jehovas'},
        {id: 'weitere-themen', title: 'Weitere Themen'}
    ]
};